import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('AppAuditing', {params});
    }, 
    export(params) {
        return Service().get('AppAuditing/export/', {params});
    },    
}